<template>
  <div>
        <b-row class="pb-50">
      <!-- text and button -->
      <b-col
        lg="8"
        order-lg="1"
        order="2"
        class="d-flex justify-content-between flex-column m-auto"
      >
        <b-card no-body class="card-statistics">
          <b-card-header>
            <b-card-title>{{ $t('Statistics') }}</b-card-title>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col xl="3" sm="6" v-for="item in items.models" :key="item.category" class="pb-1">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" v-if="item.category == 1" :variant="'light-primary'">
                      <feather-icon size="24" :icon="'TrendingUpIcon'" />
                    </b-avatar>
                    <b-avatar size="48" v-if="item.category == 2" :variant="'light-danger'">
                      <feather-icon size="24" :icon="'BoxIcon'" />
                    </b-avatar>
                    <b-avatar v-if="item.category == 3"  size="48" :variant="'light-info'">
                      <feather-icon size="24" :icon="'UserIcon'" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h2 class="font-weight-bolder mb-0 text-primary">
                      {{ item.plan }}
                    </h2>
                    <b-card-text class="h3 mb-0">
                      {{ item.count }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- dropdown and chart -->
      <b-col
        lg="4"
        cols="4"
        order-lg="2"
        order="1"
        class="d-flex justify-content-between flex-column"
      >
        <b-card class="card-congratulation-medal">
          <h2 class="pb-1">{{ $t('totalUsers') }}</h2>
          <h1 class="mb-75 mt-2 pt-50">
            <b-link style="font-size: 50px;">{{items.total || 0}}</b-link>
          </h1>
          <b-img
            :src="require('@/assets/images/illustration/badge.svg')"
            class="congratulation-medal"
            alt="Medal Pic"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { plans } from '@/libs/acl/Lookups';

export default {
  data() {
    return {
      items: [],
      plans: plans
    }
  },
  mounted() {
    this.getData();
  },
methods: {
    getData() {
      this.get({ url: 'Dashboard' }).then((data) => {
        // data.models.forEach((item) => {
        //   const updates = this.plans.find(
        //     (val) => val.id === item.category
        //   ) || {
        //     arabicName: '',
        //     englishName: '',
        //   };
        //   item.arabicName = updates.arabicName;
        // });
      this.items = data;
      });
    },
  }
}
</script>
